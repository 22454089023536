// BOOTSTRAP
@import '../../../node_modules/bootstrap/scss/_functions';
@import '../../../node_modules/bootstrap/scss/_variables';
@import '../../../node_modules/bootstrap/scss/mixins';


@import '../../Styles/varables.scss';



.navbar {
 // backdrop-filter: blur(20px);
 //background: rgba(18, 29, 26, .9);
  background: rgb(18, 29, 26);
  position: fixed;
  font-family:'PT Serif', serif;
  //font-size: 12px;
  width: 100%;
  z-index: 9;
  top: 0;


  /*.nav-item {
    padding-left:3%;
  }*/


  >.navbar-brand img {
    width: 95px;
    height: 40px;
    padding-left: 5% ;
  }
  
.dropdown-item{
  font-size: 18px ;
  font-family: 'PT Serif', serif;
}

  >.navbar-toggler {
    border: none;
   

    >.navbar-toggler-icon {
      background-image: url('../../img/hamburguer.svg');
    }
  }

  /*.navbar-light .navbar-nav .nav-link {
    color: $white-color !important;
  }*/
  
  .dropdown {
/*
    .dropdown-toggle {
      @include media-breakpoint-down(lg) {
       // font-size: 12px;
      }
    }
*/

    /*.dropdown-toggle::after {
      //margin-left: 10px;
     
    }*/
  .dropdown-item {
    color: $primary-dark-color;
    background-color: transparent;
 
      &:hover {
        background-color: $primary-light-color;
      }
    }
  }

  >.collapse.show.navbar-collapse {
    //height: 100vh;
    padding-left:3%;
    font-size: 18px;
  }

  >.collapse >.navbar-nav >.nav-item >.nav-link {
    @include media-breakpoint-down(lg) {
      font-size: 18px;
    }
   
    color: $white-color !important;
    font-weight: bold;
    transition: .4s ease color;

    &:hover {
      color: $secondary-color;
    }
  }
}
